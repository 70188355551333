body,
input {
  font-family: "Montserrat";
  margin: 10px;
  padding: 10px;
}

p {
  margin: 0px;
}

a {
  color: black;
}

#TOS,
#Privacy {
  margin: 10px;
  padding: 10px;
}

#container {
  display: flex;
  overflow: auto;
  flex-direction: row;
}

#left {
  z-index: 99;
  width: 555px;
  margin-left: 130px;
  margin-top: 60px;
}

#right {
  height: 90%;
}

#headline {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 65px;
  margin: 0px;
}

#appStore {
  margin: 20px 0 20px 0;
  width: 180px;
}

#logo {
  margin-bottom: 70px;
  width: 160px;
}

#headline-1 {
  color: #423bd1;
}

#headline-2 {
  color: #20ca9a;
}

#copy {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: black;
  margin: 25px 0 25px 0;
  line-height: 25px;
}

#illustration {
  max-width: 613px;
}

#footer {
  position: fixed;
  display: flex;
  background-color: #fafafa;
  /* width: 100%; */
  bottom: 0px;
  left:0px;
  right: 0px;
  text-align: center;
  color: black;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 10px;
  z-index: 100;
}

#social {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

@media (max-width: 1280px) {
  #illustration {
    max-width: 600px;
    /* object-fit: cover; */
  }

  #headline {
    font-size: 38px;
    line-height: 50px;
  }

  #copy {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 1150px) {
  #container {
    flex-direction: column;
    align-items: center;
  }

  #appStore {
    width: 140px;
  }

  #logo {
    margin-bottom: 40px;
  }

  #left {
    max-width: 700px;
    width: auto;
    margin: 90px 90px 0 90px;
  }

  #illustration {
    max-width: 500px;
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  #illustration {
    max-width: 300px;
  }

  #left {
    width: auto;
    margin: 30px 30px 0px 30px;
  }

  #headline {
    font-size: 30px;
    line-height: 40px;
  }

  #copy {
    font-size: 15px;
    line-height: 23px;
  }

  #social {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin: 5px;
  }

  #copyright {
    padding: 5px;
  }

  #footer {
    flex-direction: column;
  }
}
